import React from "react"
import PropTypes from "prop-types"

const VinomofoLogo = ({ alternative }) => {
  const vinoColour = alternative ? "#000" : "#fff"
  const mofoColour = "#77B204"
  const shadowColour = "#B2B2B2"

  return (
    <svg viewBox="0 0 125 32" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          d="M73.54 11.52h.06v-.2h-.26l-.18 1.25-3.08 18.83h-1.87l.03.21h2.05l3.08-18.83.17-1.26zm-16.53-3.7s.02-2.98-1.98-4.9c1.8 1.91 1.77 4.68 1.77 4.68v17.65s.05 6.48-6.41 6.48a6.12 6.12 0 0 1-4.44-1.58c.95 1.01 2.41 1.79 4.65 1.79 6.46 0 6.41-6.48 6.41-6.48V7.8zm21.26-2.1V3.53l.61-1.87h-.28l-.54 1.67v26.2l.61 1.87H73.3l-.08.21h5.66l-.61-1.87V5.72zm-13.55 7.06v-1.26h.09l-.03-.2h-.27v18.21l.61 1.87h-5.38l-.07.21h5.66l-.6-1.86V12.78zm-24-9.6V31.4h-4.13l.06.21h4.28V3.4l.57-1.73h-.28l-.5 1.52zm83.94 22.28V7.8s.02-2.97-1.98-4.9c1.79 1.92 1.77 4.7 1.77 4.7v17.64s.04 6.48-6.42 6.48a6.12 6.12 0 0 1-4.43-1.58c.95 1.01 2.4 1.79 4.64 1.79 6.46 0 6.42-6.48 6.42-6.48zm-15.6-23.8h-.15l.79 4.79-1.64-.48h-5.85v7.87h.2V6.18h5.86l1.64.48-.82-5h-.03zm-.21 16.7h-6.64v11.2l.6 1.84h-5.74l-.06.21H103l-.6-1.84v-11.2h6.65v-4.52h-.21v4.31zM94.35 7.81s.02-2.97-1.98-4.9c1.8 1.92 1.77 4.7 1.77 4.7v17.64s.05 6.48-6.41 6.48a6.12 6.12 0 0 1-4.44-1.58c.95 1.01 2.4 1.79 4.65 1.79 6.46 0 6.41-6.48 6.41-6.48V7.8zm22.08 17.44s-.05 1.29.68 1.89c-.51-.65-.47-1.68-.47-1.68V7.8s-.1-2.2 1.6-2.2c.4 0 .7.12.92.31-.24-.3-.6-.52-1.13-.52-1.7 0-1.6 2.2-1.6 2.2v17.65zm-67.65 0s-.05 1.29.69 1.89c-.52-.65-.48-1.68-.48-1.68V7.8s-.08-2.2 1.6-2.2c.4 0 .7.12.93.31-.24-.3-.6-.52-1.13-.52-1.7 0-1.6 2.2-1.6 2.2v17.65zm20.23-8.52h.09l.05-.33-.15-1.05-2.85-13.7h-.17l2.81 13.5.22 1.58zm17.11 8.52s-.05 1.29.69 1.89c-.52-.65-.48-1.68-.48-1.68V7.8s-.09-2.2 1.6-2.2c.4 0 .7.12.93.31-.24-.3-.6-.52-1.13-.52-1.7 0-1.6 2.2-1.6 2.2v17.65zm-77.7-7.34l.17 1.27h.18l-.14-1.06L6.7 4.96h.01l-.2-1.32-.13-.91.33-1.07h-.28l-.26.86.13.91.2 1.32L8.41 17.9zM31.17 1.66h-.15l4.62 15.4.4 1.38h.15l-.34-1.17-4.68-15.61zm-7.25 0h-.28l-.52 1.58v26.37l.59 1.8h-5.74l-.07.2h6.02l-.59-1.8V3.46l.59-1.8zm-7.63 0l-.7 3.09h-.01L11.13 31.4H6.5l.03.21h4.81L15.8 4.96l.75-3.3h-.25zM31.36 14.4l.06.2h-.11v17h-4.42v-.2h4.2v-17h.27z"
          fill={shadowColour}
        />
        <path
          d="M22.59.33h-6.02l.62 1.87v26.2l-.62 1.88h6.02l-.6-1.8V2.13l.6-1.8zm28.28 23.8V6.48s.1-2.2-1.6-2.2c-1.7 0-1.6 2.2-1.6 2.2v17.65s-.1 2.2 1.6 2.2c1.7 0 1.6-2.2 1.6-2.2zm4.81 0s.05 6.48-6.41 6.48-6.42-6.48-6.42-6.48V6.48S42.81 0 49.27 0s6.41 6.48 6.41 6.48v17.65zM35.18.33h-.59l.6 1.82v15.17h-.27l-.4-1.38L29.84.33h-4.89l.61 1.87v28.08h4.41v-17h.27l.4 1.4 4.68 15.6h4.28V2.06l.57-1.73h-4.98zM15 .33h.21l-.74 3.3h-.02l-4.44 26.65h-4.8L.75 3.62 0 .32h5.38L5.05 1.4l.13.91.2 1.31L7.3 16.8l.17 1.27h.27l.18-1.27L9.84 3.62h-.01l.26-1.66.08-.56L9.83.33H15z"
          fill={vinoColour}
        />
        <path
          d="M76.94 4.38V2.2l.61-1.87h-6.49L68.2 14.02l-.22 1.59h-.09l-.22-1.59L64.82.32h-6.5l.61 1.88v26.25l-.6 1.83H64l-.6-1.87V10.2h.26l.18 1.26 3.07 18.83h2.05l3.08-18.83.17-1.26h.27v18.28l-.59 1.8h5.66l-.61-1.88V4.38zM88.2 24.13V6.48s.09-2.2-1.6-2.2c-1.7 0-1.6 2.2-1.6 2.2v17.65s-.1 2.2 1.6 2.2c1.69 0 1.6-2.2 1.6-2.2zm4.81-17.65v17.65s.05 6.48-6.41 6.48c-6.47 0-6.42-6.48-6.42-6.48V6.48S80.14 0 86.61 0c6.46 0 6.41 6.48 6.41 6.48zm25.5 17.65V6.48s.08-2.2-1.6-2.2c-1.7 0-1.61 2.2-1.61 2.2v17.65s-.1 2.2 1.6 2.2c1.7 0 1.6-2.2 1.6-2.2zm4.8 0s.05 6.48-6.4 6.48c-6.47 0-6.42-6.48-6.42-6.48V6.48S110.45 0 116.9 0s6.42 6.48 6.42 6.48v17.65zM101.1 4.85v7.87h6.64v4.52h-6.64v11.2l.6 1.84h-6.02l.6-1.83V2.15l-.6-1.82h12.08l.82 5-1.64-.48h-5.85z"
          fill={mofoColour}
        />
      </g>
    </svg>
  )
}

VinomofoLogo.propTypes = {
  isAlternative: PropTypes.bool,
}

VinomofoLogo.defaultProps = {
  isAlternative: false,
}

export default VinomofoLogo
